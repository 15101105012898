import { Box } from "@mui/material";
import * as React from 'react';
import { Introduction } from "./intriduction";
import Header from "../components/header";
import { Business } from "./business";
import { WhoWeAre } from "./who_we_are";
import { Certification } from "./certification";
import ceritification from "./landing_assets/certified.png"
import { GrowNetwork } from "./grow_network";
import { PowerOfAyurvedha } from "./powerOfAyurvedha";
import { ContactUs } from "./contactUs";
import { CustomerCare } from "./customerSupport";
import { OurStrategy } from "./ourStrategy";
import { FrequentlyAskedQuestions } from "./frequentlyAskedQuestions";
import { NewsLetter } from "./newsLetter";
import Footer from "../components/footer";import { ThemeProvider, createTheme, keyframes } from '@mui/material/styles';
import { useState } from 'react'
import Header1 from "../components/header1";
import grey from '@mui/material/colors/grey'; 
import Layout from "../layout";
import { useSelector } from "react-redux";

const shine = keyframes
`0% {
  transform: translateX(0px) rotate(25deg);
}
100% {
  transform: translateX(1000px) rotate(25deg);
}`;

const slideLeftToRight = keyframes`
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
`;

const slideRightToLeft = keyframes`
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
`;

const bounce = keyframes`
0%, 20%, 40%, 60%, 80%, 100% {
    transform: translateY(0);
  }
  10% {
    transform: translateY(-10px);
  }
  30% {
    transform: translateY(-30px);
  }
  50% {
    transform: translateY(-20px);
  }
  70% {
    transform: translateY(-15px);
  }
  90% {
    transform: translateY(-5px);
  }
`;
const shadowBounce = keyframes`
  0%, 100% {
    transform: scaleX(1) scaleY(1);
    opacity: 0.5;
  }
  10% {
    transform: scaleX(1.2) scaleY(1.1);
    opacity: 0.6;
  }
  30% {
    transform: scaleX(1.4) scaleY(1.3);
    opacity: 0.7;
  }
  50% {
    transform: scaleX(1.2) scaleY(1.1);
    opacity: 0.6;
  }
  70% {
    transform: scaleX(1.1) scaleY(1.05);
    opacity: 0.5;
  }
  90% {
    transform: scaleX(1) scaleY(1);
    opacity: 0.4;
  }
`;
export const landingPage_styles = {
    fontFamily: "Poppins",
    positionAnimation:{zIndex:2,backgroundColor: "white"},
    buttonAnimation:{'&:before': {
        content: '""',
        position: 'absolute',
        top: '-20px',
        left: '-150px',
        height: '150px',
        width: '50px',
        background: 'rgba(255,255,255, 0.3)',
        filter: 'blur(1px)',
        boxShadow: '0 0 10px rgba(255,255,255, 0.1)',
        transform: 'translateX(-100%)', // Start off screen
      },
      '&:hover:before': {
        animation: `${shine} 2s linear infinite`,
      }
    },
    landing_prop: {
        maxWidth: "1440px",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },
    introduction: {
        page_prop: { display: "flex", justifyContent: "center", alignItems: "center",width:"100%",alignItems:"center",padding:{xs :"20px 16px",md:"64px 120px"} },
        button: { maxWidth: "233px", width: "100%", height: "50px", background: "linear-gradient(45deg, #00C2FF 10%, #00E3AF 90%)", color: "black", textTransform: "none",padding:"11px 24px",position: "relative",overflow: "hidden",  },
        buttonText: { fontSize: "20px", fontWeight: "500", lineHeight: "30px", textAlign: "center" },
        heading1: { fontSize: {xs:"20px",md:"32px"}, fontWeight: "400", lineHeight: {xs:"24px",md:"38.4px"}, textAlign: "center",  width: "100%" },
        heading2: { fontSize: {xs:"26px",md:"50px"}, fontWeight: "700", lineHeight: {xs:"31.2px",md:"60px"}, textAlign: "center",maxWidth:"979px",width: "100%" },
        heading2_gradient: { fontSize:  {xs:"26px",md:"50px"}, fontWeight: "700", lineHeight: {xs:"31.2px",md:"60px"}, textAlign: "center", background: "linear-gradient( 45deg, #00E471 10%,#53AEFF 90%)", WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent', backgroundClip: 'text', textFillColor: 'transparent',maxWidth:"979px", },
        image: { maxWidth: "677px", width: "100%", height:{xs:"100%",md:"490px"}  },
        discountText: { fontSize: {xs:"16px",md:"22px"}, fontWeight: "500", lineHeight: "33px" },
        discount: { fontSize: {xs:"16px",md:"20px"}, fontWeight: "700", lineHeight:{xs:"24px", md:"30px"},background:"yellow",padding:  {xs:"4px 20px",md:"8px 20px"},borderRadius:"23px" },
    },
    business: {
        page_prop: { height: {md:"640px"}, display: "flex", justifyContent: "center", alignItems: "center", width: "100%" },
        button: { maxWidth: "233px", width: "100%", height: "50px", background: "linear-gradient(45deg, #00C2FF 10%, #00E3AF 90%)", color: "black", textTransform: "none" },
        image: { width: "100%", height: {xs:"160px",md:"640px"}, objectFit: "cover" },
    },
    whoWeAre: {
        page_prop: { display: "flex", alignItems: "center", padding: {xs:"20px 16px",md:"64px 120px"}, width: "100%", flexDirection: "column",gap:"30px" },
        tabs: { width:"100%",maxWidth: "810px", display: "flex", justifyContent: "center", background: "grey.200" },
        tabActiveLTR: { maxWidth: "268px", width: "100%", height: "50px", borderRadius: "26px", padding: "10px", gap: "10px", background: "black", color: "white", fontSize: "20px", fontWeight: "500", lineHeight: "30px", textAlign: "center", animation: `${slideLeftToRight} 0.5s ease-in-out`, },
        tabActiveRTL: { maxWidth: "268px", width: "100%", height: "50px", borderRadius: "26px", padding: "10px", gap: "10px", background: "black", color: "white", fontSize: "20px", fontWeight: "500", lineHeight: "30px", textAlign: "center", animation: `${slideRightToLeft} 0.5s ease-in-out`, },
        tabInActive: { maxWidth: "268px", width: "100%", height: "50px", borderRadius: "26px", padding: "10px", gap: "10px", background: "transparent", color: "grey", fontSize: "20px", fontWeight: "500", lineHeight: "30px", textAlign: "center" },
        contentBox1: { display: "flex", gap: "30px", justifyContent: "space-between",flexDirection:{xs:"column-reverse",md:"row"},padding:{xs:"24px 0px",md:"0px"} },
        contentBox2: { display: "flex", gap: {xs:"12px"},md:"30px", justifyContent: "space-between",flexDirection:{xs:"column",md:"row"}, padding:{xs:"24px 0px",md:"0px"}},
        contentBox3: { display: "flex", gap: {xs:"24px",md:"30px"}, justifyContent: "space-between",flexDirection:{xs:"column",md:"row"}, padding:{xs:"24px 0px",md:"0px"} },
        heading: { fontSize: "20px", lineHeight: "24px", fontWeight: "600",textAlign:{xs:"center",md:"left"} },
        headingBold: { fontSize:{ xs:"28px",md: "32px"}, lineHeight:{xs:"33.6px" ,md:"44px"}, fontWeight: "600",textAlign:{xs:"center",md:"left"} },
        content: { fontSize: {xs:"16px",md:"18px"}, lineHeight: {xs:"24px",md:"30px"}, fontWeight: "400",textAlign:{xs:"center",md:"left"} },
        subContent: { fontSize: "18px", lineHeight: "30px", fontWeight: "600",textAlign:{xs:"center",md:"left"} },
        textSection: { width:{xs:"100%", md:"100%"}, display: "flex", flexDirection: "column", gap: {xs:"12px",md:"30px"} },
        image1: { width: "100%", maxWidth: {xs:"328px",md:"585px"},marginTop:{md:"-157px"}},
        image2: { width: "100%", maxWidth: "585px", },
        image3: { width: "100%", maxWidth: "615px", height: "499px" }
    },
    certificate: {
        image: { width: "100%", backgroundImage: `url(${ceritification})`, height: "314px", padding: "64px 120px"},
        overlay: { position: "absolute", height: "314px", padding: "64px 120px", background: "#005225", zIndex: 1, top: 0, width: "100%", opacity: "50%" },
        content: { fontSize: {xs:"16px", md:"20px"}, lineHeight: "24px", fontWeight: "400", color: "white", maxWidth: "790px", width: "100%", textAlign: "center" }
    },
    power: {
        page_prop: { display: "flex", alignItems: "center", padding: {xs:"24px 16px",md:"64px 120px"}, width: "100%", flexDirection: "column" },
        first_heading: { fontSize: "20px", fontWeight: "600", lineHeight: "24px", color: "#00C561",textAlign:{xs:"center",md:"left"},textTransform:"uppercase" },
        content: { fontSize: "18px", lineHeight: "30px", fontWeight: "400",textAlign:{xs:"center",md:"left"} },
        BoxHeading: { fontSize: "30px", fontWeight: "600", lineHeight: "36px" },
        BoxSubHeading: { fontSize: "18px", fontWeight: "500", lineHeight: "28px" },
        BoxContent: { fontSize: "16px", fontWeight: "400", lineHeight: "24px" }
    },
    ourStrategy: {
        box: {  width: {xs:"261px",md:"100%"}, padding:{xs:"24px 16px",md: "16px"}, display: "flex", gap: "16px", flexDirection: "column", boxShadow: "11px 4px 20px 0px #0000001A" ,bgcolor:"white"},
        heading: { fontSize: "20px", lineHeight: "24px", fontWeight: "600",textAlign:"left"},
        content: { fontSize: {xs:"16px",md:"18px"}, lineHeight: {xs:"24px",md:"30px"}, fontWeight: "400",textAlign:"left"},
    },
    ayur:{
        page_prop: { display: "flex", alignItems: "center", padding: {xs:"24px 16px", md:"64px 120px 64px 0px"},  width: "100%", flexDirection: "column" },
    },
    frequentlyAskedQuestions: {
        heading: { fontSize: {xs:"16px",md:"20px"}, lineHeight: {xs:"24px",md:"44px"}, fontWeight: "400" ,textAlign:{xs:"center",md:"left"}},
        headings:{color: "black.500", fontSize:"18px", fontWeight: "500", lineHeight: "28px"},
        content:{color: "grey.600", fontSize: "16px", fontWeight: "400", lineHeight: "24px", letterSpacing: "1px"},
        accordians:{display:"flex", flexDirection: "column", maxWidth:"1440px" ,gap:"24px",padding: {xs:0, md:0 , lg:0,xl:"0px 120px" }},
        bounceBox:{ maxWidth: "64px", width: "100%", height: "64px", animation: `${bounce} 2s ease-in-out infinite`, },
        shadow: {position: 'absolute',bottom: '-20px', left: '12px',width: '40px',height: '10px',backgroundColor: 'rgb(0 0 0 / 15%)',borderRadius: '50%',animation: `${shadowBounce} 2s ease-in-out infinite`, },
    },
    contactUs:{
        button:{background:"black",color:"white",maxWidth:"282px",width:"100%",radius:"4px",padding:"10px 24px",maxWidth:{md:"282px"},alignSelf:"center",display:"flex",gap:"10px",overflow:"hidden","&:hover":{background:"black",color:"white"}},
        buttonText:{color:"white",textTransform:"none",fontSize:"20px",fontWeight:"500",lineHeight:"30px"}
    }
}
const theme = createTheme({
   
    typography: {
      fontFamily: 'Poppins',
    },
  });
export function LandingPage() {
  const showChatBot = useSelector(state=>state.user.chatbot)
    return (
        <ThemeProvider theme={theme}>
            <Box sx={{ width: "100%", display: "flex", alignItems: "center", flexDirection: "column" }}>
                <Layout/>
                <Introduction />
                 <Business />
               <WhoWeAre />
                 <Certification />
               <GrowNetwork />
                 <PowerOfAyurvedha />
                <CustomerCare />
                <ContactUs />
                <OurStrategy />
                <FrequentlyAskedQuestions />
                <NewsLetter/>
            <Footer/>
            {showChatBot && (
            <iframe
                title="Chatbot"
                src="https://copilotstudio.preview.microsoft.com/environments/5677e7c0-a0c6-e5bc-abb7-e6fc5d85a51e/bots/cr4d9_copilot1/webchat?__version__=2" // Replace with the correct path
                style={{ width: '300px', height: '500px', border: 'none',borderRadius:"10px",zIndex:1000,position:"fixed",bottom:"100px",left:{xs:"20px",sm:"100px",md:"100px"},boxShadow:" 8.0px 16.0px 16.0px hsl(0deg 0% 0% / 0.25)","&::-webkit-scrollbar":{display:"none"} }}
            />
        )}
            </Box>
        </ThemeProvider>
    )
}