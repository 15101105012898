import * as React from 'react';
import { Box, Typography, Divider, Buttonz,InputBase,Button } from '@mui/material';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import prodOne from "../assets/prodOne.png"
import { useSelector, useDispatch } from 'react-redux';
import { updateSideBar, updateCartSideBar } from '../redux/active_bar';
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';
import SideBarList from './list';
import CancelIcon from '@mui/icons-material/Cancel';
import { useState } from 'react';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import { useMediaQuery } from 'react-responsive';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import { updateCart } from '../redux/products';
import axios from 'axios';
import CustomAxios from '../utils/CustomAxios';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import { updateSnackBarMessage, updateSnackBarSeverity, updateSnackBarOpen } from "../redux/snackbar";
import { useEffect } from 'react';
import MessageBar from '../modules/messageBar';
import { updateMemberResponse, updateUserData } from '../redux/user_reducer';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';

const Cart = () => {
    const dispatch = useDispatch()
    let user_accDetails = useSelector(state => state.user.member_response.account)
    let user_accDetails_afterdc = useSelector(state => state.user.member_response)
    const [referralCode,setReferralCode]=useState("")
    const [errors,setErrors]=useState({})
    const id=useSelector(state=>state.user.id) 

    // console.log(user_accDetails)
    const [state, setState] = React.useState({
        right: false,
    });
    const [total, setTotal] = useState()
    // const [item, setItem] = useState()
    const cart = useSelector(state => state.product.cart)
    const cartSidebar = useSelector(state => state.sideBar.cartSidebar)
    const authtoken=localStorage.getItem("authtoken")
    const navigate=useNavigate()
    React.useEffect(() => {
        // // console.log("toggle bar", cartSidebar)
        // toggleDrawer('right',cartSidebar)
        setState({ ...state, ['right']: cartSidebar })
    }, [cartSidebar])

    useEffect(() => {
        // const calculateTotal = () => {
            let totalPrice = 0;
            cart.forEach(item => {
                const price = authtoken? (parseFloat(item.product?.selling_price) + Math.round(parseFloat(item.product?.selling_price) * (parseFloat(item.product?.tax)/100)  )).toFixed(2): (parseFloat(item.product?.price) + Math.round(item.product?.price * (item.product?.tax/100) )) .toFixed(2)
                
                totalPrice += parseInt(price) * item.quantity;
            //    console.log(totalPrice)
            });
        // }; 
        // console.log(totalPrice)
        setTotal(totalPrice.toFixed(2));
        // calculateTotal();
    }, [cart, authtoken]);
// console.log(total)
    const toggleDrawer = (anchor, open) => (event) => {
        // // console.log(anchor)
        setReferralCode("")
        setErrors({})
        if (
            // cartSidebar
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }
        if (!open) {
            dispatch(updateCartSideBar(false))
        }
        setState({ ...state, [anchor]: open });
    };
    const isMobile = useMediaQuery({ maxWidth: 600 });
    const clearCart = (item) => {
        const prevCart = [...cart]
        // // console.log()
        const newCart = prevCart.filter(ct => ct.product?.id !== item.product?.id)
        CustomAxios.delete(`cart/${item.id}/`)

        // // console.log(newCart)/
        dispatch(updateCart(newCart))
        localStorage.setItem("cart", JSON.stringify(newCart))
        // // console.log( localStorage.setItem("cart",newCart))
        // // console.log()
    }
    const updateQuantity = async (object, condition) => {

        let products = [...cart]
        const newCart = products.map(item => {
            if (condition && item.product?.id == object.product?.id) {
                // console.log("if condition")
                let updated = { ...item }
                updated.quantity += 1

                if (item.id) {
                    CustomAxios.patch(`cart/${item.id}/`, {
                        quantity: updated.quantity,
                        totalprice:updated.quantity*(parseFloat(updated.price)+ Math.round(updated?.price * (updated.product?.tax/100) ) )
                    })
                    return updated
                }
                else {

                    return updated

                }

            }
            else if (!condition && item.product?.id == object.product?.id) {

                let updated = { ...item }
                updated.quantity -= 1

                if (item.id && updated.quantity >= 1) {
                    // console.log(updated.quantity)
                    CustomAxios.patch(`cart/${item.id}/`, {
                        quantity: updated.quantity,
                        totalprice:updated.quantity*(parseFloat(updated.price)+ Math.round(updated?.price * (updated.product?.tax/100) ) )
                    })
                    return updated
                }
                else if (item.id && updated.quantity < 1) {
                    CustomAxios.delete(`cart/${item.id}/`)
                    return updated
                }
                else {

                    return updated

                }



            }
            else {
                return item

            }

        })

        // console.log(newCart)
        // setProduct(products)
        dispatch(updateCart(newCart.filter((item) => !item.quantity < 1)))
        localStorage.setItem("cart", JSON.stringify(newCart.filter((item) => !item.quantity < 1)))
        dispatch(updateSnackBarMessage("Successfully Updated the  Cart!"))
        dispatch(updateSnackBarSeverity("success"))
     
      dispatch(updateSnackBarOpen(true))
    }
    const updateReferral=()=>{
        const payload={
          referral:referralCode,
          id:id
        }
        CustomAxios.post ("update-eferral/",payload).then(({data})=>{
            if(data.message=="Successfully Updated!"){
                // console.log(data.user)
      
                dispatch(updateMemberResponse(data.user))

                dispatch(updateSnackBarMessage("Sponsor Updated Successfully"))
            dispatch(updateSnackBarSeverity("success"))
          
          dispatch(updateSnackBarOpen(true))
        //   dispatch(updateCartSideBar(false))
        // window.location.reload();
            }
            else{
                setErrors({referralCode:data.message})
            }

        }


        )
        
    
      }

    // React.useEffect(() => {
    //     const total = cart.reduce((a, b) => a + parseInt(authtoken? parseFloat(b.product?.selling_price) + Math.round(parseFloat(b.product?.selling_price) * (parseFloat(b.product?.tax)/100) ) : (parseFloat(b.product?.price) + Math.round(b.product?.price * (b.product?.tax/100) )) * b.quantity), 0)

    //     setTotal(parseFloat(total).toFixed(2))
    // }, [cart])

    const checkOut=()=>{
        dispatch(updateCartSideBar(false))
        if(authtoken){

            navigate("/checkout")
        }
        else{
            navigate("/login")
        }
    }
    return (
        <div>

            <React.Fragment key={'right'}>
                {/* <Button onClick={toggleDrawer('right', true)} sx={{zIndex:"10"}}>{'right'}</Button> */}
                <SwipeableDrawer
                    anchor={"right"}
                    open={state["right"]}
                    onClose={toggleDrawer("right", false)}
                    onOpen={toggleDrawer("right", true)}
                    sx={{marginRight:0}}
                >
                    <MessageBar/>

                    <Box sx={{ width: {xs :cart.length <= 0?"300px":"100%",sm : "448px"},zIndex:"20" }}>


                        <Box sx={{ padding: "16px 24px", display: "flex", justifyContent: "space-between", alignItems: "center",height:"61px",alignItems:"center" }}>

                            <Box sx={{ display: "flex", gap: "12px" }}><ShoppingCartOutlinedIcon sx={{ color: "primary.light",height:"24px",width:"22px", fontSize: "28px", fontWeight: "600" }} />  <Typography sx={{ color: "primary.light", fontSize: "16px", fontWeight: "600",display:"flex",alignItems:"flex-end" }} > {cart.length > 0 && cart.length} Item</Typography></Box>



                            {/* <IconButton sx={{ backgroundColor: 'grey.200',width:"28px",height:"28px","&:hover":{bgcolor:"primary.light",color:"#FFF"}}} onClick={() => dispatch(updateCartSideBar(false))} > */}
                                <CloseIcon onClick={() => dispatch(updateCartSideBar(false))} sx={{ backgroundColor: 'grey.200',width:"28px",height:"28px",color:"grey.500",borderRadius:"50px",padding:"5px","&:hover":{bgcolor:"primary.light",color:"#fff",cursor:"pointer"},transition:".3s ease-in-out" }} />
                                {/* </IconButton> */}

                        </Box>
                        <Divider />
                        {cart.length <= 0 ? <Box sx={{ display: 'flex', alignItems: "center", width: "100%", justifyContent: "center", height: "calc(100vh - 60px) " }}>
                            <ShoppingBagIcon sx={{ color: "primary.light", opacity: "0.5", fontSize: "150px" }} />
                            { user_accDetails?.referred_by_id == 2&& <Box sx={{display:"flex",alignItems:"center",position:"fixed",bottom:"80px",bgcolor:"rgba(255,255,255,0.7)",width: { xs: "80%", sm: "85%" }, maxWidth: { xs: "300px", sm: "400px" } ,padding:"20px 0px",justifyContent:"center",backdropFilter: "blur(40px)",}}>
                            <Box sx={{ width: "100%", maxWidth: "289px", alignSelf: "center" ,zIndex:5}}>
                                <Typography sx={{ fontSize: "14px", fontWeight: "700", textAlign: "center",color: "primary.light", }} >Enter Sponsor Referral Code</Typography>
                                <Typography sx={{ fontSize: "12px", fontWeight: "400", textAlign: "center" }} >Unlock exclusive benefits by entering a valid referral code. Activate your membership and make your first purchase with MAYI IQ. Plus, when you refer others with your username / referral code, you'll earn commissions on their purchases!</Typography>
                                <Box sx={{display:"flex",gap:"3px",marginTop:"16px"}}>                            
                                    
                                        {/* <Typography sx={{ fontWeight: 400, fontSize: "12px",lineHeight:"16px"}} color="error">Invalid code. Please enter the valid code.</Typography> */}
                                        <InputBase value={referralCode} onChange={(e) => setReferralCode(e.target.value.replace(" ", ""))} placeholder="Referral Code/Username" sx={{ border: "1px solid", borderColor: "grey.400", width: "100%", borderRadius: "5px", height: "48px", fontSize: "16px", padding: "14px", color: "grey.700" }}></InputBase>
                                        

                                    
                                        <Button disabled={!referralCode} variant="contained" sx={{ bgcolor: "primary.light", color: "primary.contrastText", height: "48px", maxWidth: "77px", width: "100%", textTransform: "none" }} onClick={updateReferral} >Submit</Button>
                                    
                                        {/* <Button sx={{bgcolor:"transparent",border:"none",outline:"none",textTransform:"none"}}><Typography sx={{fontWeight: 400, fontSize: "16px",lineHeight:"24px",}} color={resend&& time==0 ?"primary":"#BFC7D4"}>Resend OTP</Typography></Button> */}
                                    
                                </Box>
                                <Typography sx={{ fontWeight: 400, fontSize: "12px", lineHeight: "16px", margin: "5px 0px" }} color="error">{errors.referralCode}</Typography>

                                </Box> 
                                    </Box>}


                        </Box> :
                            <Box sx={{ height: "calc(100vh - 60px) ", }}>

                                {cart.length > 0 && cart.map((item,item_idx) => {

                                    {/* <ShoppingBagIcon sx={{ color: "primary.light", opacity: "0.7", fontSize: "150px" }} /> */ }
                                    return <Box key={item_idx} sx={{ display: "flex", padding:  "16px 24px" , alignItems: "center", justifyContent: "space-between",borderBottom:"1px solid rgb(229, 228, 226)"  }} >
                                        <Box sx={{ display: "flex", alignItems: "start",gap:"15px",flexGrow:2 }}>
                                            
                                            <Box component='img' sx={{ width: "100px", height: "100px" }} src={ item?.product?.cover_details?.original || prodOne} />
                                            <Box sx={{ display: "flex", flexDirection: "column", flexGrow:2 }}>
                                                <Typography sx={{ fontSize: "14px", fontWeight: "600" }}> {item.product?.name}</Typography>
                                                <Typography sx={{ fontSize: "8px", fontWeight: "400", color: "grey.500", displa: "flex", alignItems: "center", justifyContent: "center",fontSize:"12px" }}> {item.quantity}<CloseIcon sx={{ fontSize: "14px", textAlign: "center" }} />{item.product?.unit}</Typography>
                                                <Box sx={{display:"flex",justifyContent:"space-between",flexGrow:2 }}>
                                                <Typography sx={{ fontSize: "16px", color: "primary.light", fontWeight: "600",marginTop:"8px",marginBottom:"4px" }}> <CurrencyRupeeIcon sx={{fontSize:"14px",fontWeight: 600}}/>{authtoken ? (parseFloat(item.product?.selling_price) + Math.round(item.product?.selling_price * (item.product?.tax/100) )).toFixed(2): (parseFloat(item.product?.price)  + Math.round(item.product?.price * item.product?.tax/100)).toFixed(2)}</Typography>
                                                <Typography sx={{display:"flex",alignItems:"center",fontSize:"16px",fontWeight:"600"}}><CurrencyRupeeIcon sx={{fontSize:"16px",fontWeight:"600"}}/>{parseFloat(item.quantity * (authtoken ?  parseFloat(item.product?.selling_price) + Math.round(parseFloat(item.product?.selling_price) * (parseFloat(item.product?.tax)/100) ) : parseFloat(item.product?.price) + Math.round(item.product?.price * item.product?.tax/100))).toFixed(2)}</Typography>
                                                    </Box>
                                             
                                                <Box sx={{ display: "flex", flexDirection: 'row', gap: "4px", bgcolor: "grey.100", padding: "4px 12px", borderTopRightRadius: "20px", borderTopLeftRadius: "20px", borderBottomLeftRadius: "20px", borderBottomRightRadius: "20px", justifyContent: "space-between", alignItems: "center",maxWidth:"90px"}}>

                                                
                                                <Typography onClick={() => updateQuantity(item, false)} sx={{ fontSize: "14px",fontWeight:"400",  cursor: "pointer" }}>
                                                    -
                                                </Typography>
                                                <Typography sx={{ fontSize: "14px",fontWeight:"400",  }}>
                                                    {item.quantity}
                                                </Typography>
                                                <Typography onClick={() => updateQuantity(item, true)} sx={{ fontSize: "14px",fontWeight:"400", cursor: "pointer" }}>
                                                    +
                                                </Typography>
                                                
                                            </Box>
                                            </Box>
                                            
                                        </Box>
                                        

                                            
                                            <CloseIcon onClick={()=>clearCart(item)} sx={{alignSelf:"start", width:"28px",height:"28px",color:"grey.500",borderRadius:"50px",padding:"5px","&:hover":{bgcolor:"grey.200",color:"error.dark",cursor:"pointer"},transition:".3s ease-in-out" }} />

                                        

                                    </Box>




                                })}
                  
                        <Box sx={{ display: "flex", alignItems: 'center', justifyContent: "center", width: "100%" }}>
                       { user_accDetails?.referred_by_id == 2&& <Box sx={{display:"flex",alignItems:"center",position:"fixed",bottom:"80px",bgcolor:"rgba(255,255,255,0.7)",width: { xs: "80%", sm: "85%" }, maxWidth: { xs: "300px", sm: "400px" } ,padding:"20px 0px",justifyContent:"center",backdropFilter: "blur(40px)",}}>

                                <Box sx={{ width: "100%", maxWidth: "289px", alignSelf: "center" ,zIndex:5}}>
                                <Typography sx={{ fontSize: "14px", fontWeight: "700", textAlign: "center",color: "primary.light", }} >Enter Sponsor Referral Code</Typography>
                                <Typography sx={{ fontSize: "12px", fontWeight: "400", textAlign: "center" }} >Unlock exclusive benefits by entering a valid referral code. Activate your membership and make your first purchase with MAYI IQ. Plus, when you refer others with your username / referral code, you'll earn commissions on their purchases!</Typography>
                                <Box sx={{display:"flex",gap:"3px",marginTop:"16px"}}>
                                
                                        {/* <Typography sx={{ fontWeight: 400, fontSize: "12px",lineHeight:"16px"}} color="error">Invalid code. Please enter the valid code.</Typography> */}
                                        <InputBase value={referralCode} onChange={(e) => setReferralCode(e.target.value.replace(" ", ""))} placeholder="Referral Code/Username" sx={{ border: "1px solid", borderColor: "grey.400", width: "100%", borderRadius: "5px", height: "48px", fontSize: "16px", padding: "14px", color: "grey.700" }}></InputBase>
                                        

                                    
                                        <Button disabled={!referralCode} variant="contained" sx={{ bgcolor: "primary.light", color: "primary.contrastText", height: "48px", maxWidth: "77px", width: "100%", textTransform: "none" }} onClick={updateReferral} >Submit</Button>
                                    
                                        {/* <Button sx={{bgcolor:"transparent",border:"none",outline:"none",textTransform:"none"}}><Typography sx={{fontWeight: 400, fontSize: "16px",lineHeight:"24px",}} color={resend&& time==0 ?"primary":"#BFC7D4"}>Resend OTP</Typography></Button> */}
                                    
                                </Box>
                                <Typography sx={{ fontWeight: 400, fontSize: "12px", lineHeight: "16px", margin: "5px 0px" }} color="error">{errors.referralCode}</Typography>

                                </Box> 
                                </Box>}
                            
        
                                    {/* {
                                        user_accDetails?.kyc_status  || user_accDetails_afterdc?.kyc_status ? */}

                                    <Box sx={{ position: "fixed", bottom: "20px",cursor: user_accDetails?.referred_by_id == 2 ? "not-allowed " : "pointer", width: { xs: "80%", sm: "85%" }, maxWidth: { xs: "300px", sm: "400px" } }} onClick={() => { if (user_accDetails?.referred_by_id !== 2) { checkOut() } }}>
                                        <Box sx={{ display: "flex", alignItems: 'center', justifyContent: "space-between", bgcolor: user_accDetails?.referred_by_id == 2 ? "grey.500" : "primary.light", height: "56px", borderRadius: "28px", padding: "16px 4px 16px 24px" }}>


                                            <Typography sx={{ color: "primary.contrastText", fontWeight: "600", fontSize: "14px" }}>
                                                Checkout
                                            </Typography>
                                            <Box sx={{ bgcolor: "primary.contrastText", borderRadius: "50px", height: "50px", padding: "0px 20px", display: "flex", alignItems: "center" }}>
                                                <Typography sx={{ color: user_accDetails?.referred_by_id == 2 ? "grey.500" : "primary.light", fontWeight: 600, fontSize: "14px" }}>
                                                    <CurrencyRupeeIcon sx={{ fontWeight: 600, fontSize: "14px" }} />{total}
                                                </Typography>

                                            </Box>
                                        </Box>

                                    </Box>

                                    {/* :
                                        <Button variant='contained' color='error' onClick={()=>{dispatch(updateCartSideBar(false));navigate('/profile/?scrollto=kyc')}} sx={{ position: "fixed", bottom: "20px",width:{xs:"80%", sm:"85%"},maxWidth:{xs:"300px",sm:"400px"} }}>Complete KYC</Button>
                                     }   */}
                                </Box>
                            </Box>}

                    </Box>


                </SwipeableDrawer>
            </React.Fragment>

        </div>
    );
}
export default Cart